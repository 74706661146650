import { Link } from "react-router-dom";

const HomePage = () => {
    return (
        <div>
            <section className="wrapper bg-yellow angled lower-start">
                <div className="container pt-7 pt-md-11 pb-8">
                    <div className="row gx-0 gy-10 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-1 text-navy mb-4">Connecting Visionary Businesses with Exceptional Tech Talent!</h1>
                            <p className="lead fs-24 lh-sm text-navy mb-7 pe-md-18 pe-lg-0 pe-xxl-15">We carefully consider our solutions to support each and every stage of your growth.</p>
                            <div>
                                <a href="mailto:info@conchinc.com" className="btn btn-lg btn-primary rounded">Connect Now</a>
                            </div>
                        </div>
                        {/* /column */}
                        <div className="col-lg-5 offset-lg-1 mb-n18">
                            <div className="position-relative">
                                <a href="./assets/media/movie.mp4" className="btn btn-circle btn-primary btn-play ripple mx-auto mb-6 position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', zIndex: 3 }} data-glightbox><i className="icn-caret-right" /></a>
                                <figure className="rounded shadow-lg"><img src="./assets/img/photos/about13.jpg" srcSet="./assets/img/photos/about13@2x.jpg 2x" alt /></figure>
                            </div>
                            {/* /div */}
                        </div>
                        {/* /column */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
            {/* /section */}
            <section className="wrapper bg-light">
                <div className="container pt-19 pt-md-21 pb-16 pb-md-18">
                    <div className="row">
                        <div className="col-lg-12 col-xl-7 col-xxl-12">                            
                            <p className="display-8 mb-9"><b>Welcome to Conch Digital,</b> your ultimate destination for top-notch IT recruitment solutions in the United States. We specialize in connecting visionary businesses with the finest tech talent, enabling them to build agile, innovative, and high-performing teams. Our comprehensive range of services is tailored to cater to the diverse needs of businesses across various industries.</p>
                        </div>
                        {/* /column */}
                    </div>
                    {/* /.row */}
                    <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
                        <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-primary pe-none mb-6"> <i className="uil uil-phone-volume" /> </div>
                            <h4>Permanent IT Staffing</h4>
                            <p className="mb-3">Finding the right permanent IT professionals is vital for your organization's long-term success. Our dedicated team of recruiters meticulously matches your requirements with candidates who possess the ideal skill set...</p>
                            <Link to={"/aboutus"} className="more hover link-primary">Learn More</Link>
                        </div>
                        {/*/column */}
                        <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-primary pe-none mb-6"> <i className="uil uil-shield-exclamation" /> </div>
                            <h4>Contract and Temporary Staffing</h4>
                            <p className="mb-3">When your projects demand skilled IT experts for a specific duration, our contract and temporary staffing services provide you with the agility to scale your workforce as needed. From short-term assignments...</p>
                            <Link to={"/aboutus"} className="more hover link-primary">Learn More</Link>
                        </div>
                        {/*/column */}
                        <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-primary pe-none mb-6"> <i className="uil uil-laptop-cloud" /> </div>
                            <h4>IT Executive Search</h4>
                            <p className="mb-3">For leadership roles and executive-level positions, our IT executive search service identifies exceptional leaders who can steer your organization towards growth and success. We focus on experience, expertise, and a track record...</p>
                            <Link to={"/aboutus"} className="more hover link-primary">Learn More</Link>
                        </div>
                        {/*/column */}
                        <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-primary pe-none mb-6"> <i className="uil uil-chart-line" /> </div>
                            <h4>Specialized IT Roles</h4>
                            <p className="mb-3">From cybersecurity specialists to cloud architects, data scientists to AI engineers, we excel in sourcing talent for specialized IT roles. Our vast talent network ensures that you have access to top professionals in cutting-edge technologies.</p>
                            <Link to={"/aboutus"} className="more hover link-primary">Learn More</Link>
                        </div>
                        {/*/column */}
                    </div>
                    {/*/.row */}
                    <div className="row gy-10 gy-sm-13 gx-lg-3 mb-16 mb-md-18 align-items-center">
                        <div className="col-md-8 col-lg-6 position-relative">
                            <div className="shape bg-dot primary rellax w-17 h-21" data-rellax-speed={1} style={{ top: '-2rem', left: '-1.9rem' }} />
                            <div className="shape rounded bg-soft-primary rellax d-md-block" data-rellax-speed={0} style={{ bottom: '-1.8rem', right: '-1.5rem', width: '85%', height: '90%' }} />
                            <figure className="rounded"><img src="./assets/img/photos/about7.jpg" srcSet="/assets/img/photos/about7@2x.jpg 2x" alt /></figure>
                        </div>
                        {/*/column */}
                        <div className="col-lg-5 col-xl-4 offset-lg-1">
                            <h2 className="fs-16 text-uppercase text-line text-primary mb-3">How It Works?</h2>
                            <h5 className="display-8 mb-7">We believe in simplifying the IT recruitment process to ensure efficiency, accuracy, and seamless candidate placements</h5>
                            <div className="d-flex flex-row mb-6">
                                <div>
                                    <span className="icon btn btn-block btn-soft-primary pe-none me-5"><span className="number fs-18">1</span></span>
                                </div>
                                <div>
                                    <h4 className="mb-1">Initial Consultation</h4>
                                    <p className="mb-0">Our journey begins with an in-depth consultation with your team. We take the time to understand your business, its culture, objectives, and the specific IT roles you are looking to fill.</p>
                                </div>
                            </div>
                            <div className="d-flex flex-row mb-6">
                                <div>
                                    <span className="icon btn btn-block btn-soft-primary pe-none me-5"><span className="number fs-18">2</span></span>
                                </div>
                                <div>
                                    <h4 className="mb-1">Candidate Search and Sourcing</h4>
                                    <p className="mb-0">Leveraging our extensive network and cutting-edge recruitment tools, we embark on a comprehensive search for the best-suited candidates.</p>
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div>
                                    <span className="icon btn btn-block btn-soft-primary pe-none me-5"><span className="number fs-18">3</span></span>
                                </div>
                                <div>
                                    <h4 className="mb-1">Interview Coordination</h4>
                                    <p className="mb-0">We take care of all the logistics and coordination involved in scheduling interviews with your team and the shortlisted candidates.</p>
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div>
                                    <span className="icon btn btn-block btn-soft-primary pe-none me-5"><span className="number fs-18">4</span></span>
                                </div>
                                <div>
                                    <h4 className="mb-1">Onboarding Support</h4>
                                    <p className="mb-0">Once you have selected the candidates, we continue to support you through the onboarding process. We provide any necessary assistance to ensure a seamless integration of the new employees into your organization.</p>
                                </div>
                            </div>
                        </div>
                        {/*/column */}
                    </div>
                    {/*/.row */}
                    <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center">
                        <div className="col-md-8 col-lg-6 offset-lg-1 order-lg-2 position-relative">
                            <div className="shape rounded-circle bg-line primary rellax w-18 h-18" data-rellax-speed={1} style={{ top: '-2rem', right: '-1.9rem' }} />
                            <div className="shape rounded bg-soft-primary rellax d-md-block" data-rellax-speed={0} style={{ bottom: '-1.8rem', left: '-1.5rem', width: '85%', height: '90%' }} />
                            <figure className="rounded"><img src="./assets/img/photos/about9.jpg" srcSet="./assets/img/photos/about9@2x.jpg 2x" alt /></figure>
                        </div>
                        {/*/column */}
                        <div className="col-lg-5">
                            <h2 className="fs-16 text-uppercase text-line text-primary mb-3">Why Choose Us?</h2>
                            <h3 className="display-4 mb-7">A few reasons why our valued customers choose us.</h3>
                            <div className="accordion accordion-wrapper" id="accordionExample">
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingOne">
                                        <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Industry Expertise</button>
                                    </div>
                                    {/*/.card-header */}
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>With our in-depth knowledge of the IT industry, we understand the evolving technology landscape and the skills needed to drive businesses forward.</p>
                                        </div>
                                        {/*/.card-body */}
                                    </div>
                                    {/*/.accordion-collapse */}
                                </div>
                                {/*/.accordion-item */}
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingTwo">
                                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Extensive Talent Network</button>
                                    </div>
                                    {/*/.card-header */}
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>Our vast talent pool is constantly updated, ensuring that we can swiftly find the best-matched candidates for your specific requirements.</p>
                                        </div>
                                        {/*/.card-body */}
                                    </div>
                                    {/*/.accordion-collapse */}
                                </div>
                                {/*/.accordion-item */}
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingThree">
                                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Customized Approach</button>
                                    </div>
                                    {/*/.card-header */}
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            <p> We take the time to understand your organization's culture, values, and goals to provide personalized solutions that align with your long-term vision.</p>
                                        </div>
                                        {/*/.card-body */}
                                    </div>
                                    {/*/.accordion-collapse */}
                                </div>
                                {/*/.accordion-item */}
                                {/*/.accordion-item */}
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingFour">
                                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Rigorous Screening Process</button>
                                    </div>
                                    {/*/.card-header */}
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>Our stringent screening process evaluates technical proficiency, communication skills, and cultural fit, ensuring that only the finest candidates are presented to you.</p>
                                        </div>
                                        {/*/.card-body */}
                                    </div>
                                    {/*/.accordion-collapse */}
                                </div>
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingFive">
                                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Client-Centric Focus</button>
                                    </div>
                                    {/*/.card-header */}
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>We prioritize building lasting partnerships and are committed to delivering exceptional service that exceeds your expectations.</p>
                                        </div>
                                        {/*/.card-body */}
                                    </div>
                                    {/*/.accordion-collapse */}
                                </div>
                            </div>
                            {/*/.accordion */}
                        </div>
                        {/*/column */}
                    </div>
                    {/*/.row */}
                </div>
                {/* /.container */}
            </section>
            {/* /section */}
            <section className="wrapper bg-yellow">
                <div className="container py-18">
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="fs-16 text-uppercase text-line text-white mb-3">Join Our Community</h2>
                            <h3 className="display-4 mb-6 text-white pe-xxl-18">We are trusted by over 100+ clients. Join them by using our services and grow your business.</h3>
                            <Link to="mailto:info@conchinc.com" className="btn btn-white rounded mb-0 text-nowrap">Join Us</Link>
                        </div>
                        {/* /column */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
        </div>
    )
};
export default HomePage;