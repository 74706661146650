import Footer from './Footer';
import Header from './Header';
const Aboutus = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <div>
                    <section className="wrapper bg-gray">
                        <div className="container pt-10 pt-md-14 text-center">
                            <div className="row">
                                <div className="col-xl-6 mx-auto">
                                    <h1 className="display-1 mb-4">Hello! This is Conch Digital</h1>
                                    <p className="lead fs-lg mb-0">Connecting Visionary Businesses with Exceptional Tech Talent</p>
                                </div>
                                {/* /column */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container */}
                        <figure className="position-absoute" style={{ bottom: 0, left: 0, zIndex: 2 }}><img src="./assets/img/photos/bg12.jpg" alt /></figure>
                    </section>
                    {/* /section */}
                    <section className="wrapper bg-light angled upper-end lower-end">
                        <div className="container py-14 py-md-16">
                            <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-center">
                                <div className="col-lg-6 position-relative order-lg-2">
                                    <div className="shape bg-dot primary rellax w-16 h-20" data-rellax-speed={1} style={{ top: '3rem', left: '5.5rem' }} />
                                    <div className="overlap-grid overlap-grid-2">
                                        <div className="item">
                                            <figure className="rounded shadow"><img src="./assets/img/photos/about2.jpg" srcSet="./assets/img/photos/about2@2x.jpg 2x" alt /></figure>
                                        </div>
                                        <div className="item">
                                            <figure className="rounded shadow"><img src="./assets/img/photos/about3.jpg" srcSet="./assets/img/photos/about3@2x.jpg 2x" alt /></figure>
                                        </div>
                                    </div>
                                </div>
                                {/*/column */}
                                <div className="col-lg-6">
                                    <img src="./assets/img/icons/lineal/megaphone.svg" className="svg-inject icon-svg icon-svg-md mb-4" alt />
                                    <h2 className="display-4 mb-3">About Us - Your Trusted Partner in US IT Recruitment</h2>
                                    <p className="mb-6">At Conch Digital, we are passionate about bridging the gap between visionary businesses and exceptional tech talent. Our mission is to empower organizations across various industries with the skilled IT professionals they need to thrive in today's rapidly evolving digital landscape. With a focus on quality, reliability, and personalized service, we have earned a reputation as a leading IT recruitment agency in the United States.</p>
                                    {/*/.row */}
                                </div>
                                {/*/column */}
                            </div>
                            <h3 className="h2 mb-3 mt-9">Why Choose Us?:</h3>
                            <div className="row gy-3 gx-xl-8 mb-10">
                                <div className="col-xl-12">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check" /></span><span>Extensive Industry Knowledge: With years of experience in the IT recruitment domain, we have developed a deep understanding of the technology landscape, market trends, and the specific skill sets required by different businesses.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Vast Talent Network: Our extensive network of IT professionals includes developers, engineers, analysts, project managers, and consultants, among others. We continuously expand and nurture this talent pool to meet the diverse needs of our clients.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Customized Solutions: We believe in a consultative approach to recruitment. Our team takes the time to understand your unique business requirements, company culture, and future goals. This allows us to identify candidates who not only possess the technical expertise but also align with your values and vision.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Quality Assurance: Our stringent screening and evaluation process ensures that only the most qualified and reliable candidates are presented to you. We verify credentials, assess technical skills, and thoroughly interview potential candidates to maintain the highest standards of quality.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Timely Delivery: We understand the importance of time in the hiring process. Our efficient recruitment methodologies allow us to deliver timely solutions, helping you onboard the right professionals without delays.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Long-Term Partnerships: Our commitment doesn't end with successful placements. We value long-term partnerships and aim to be your go-to recruitment agency for all your IT staffing needs.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <h3 className="h2 mb-3 mt-9">Our Services:</h3>
                            <div className="row gy-3 gx-xl-8 mb-10">
                                <div className="col-xl-12">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check" /></span><span>Permanent IT Staffing: Find the perfect long-term addition to your team with our permanent IT recruitment services. We source candidates who are dedicated to growing with your organization.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Temporary Staffing: Need flexible IT professionals for specific projects or temporary roles? Our temporary staffing solutions offer the flexibility to scale your team as required.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Contract-to-Hire: Test the compatibility and performance of potential employees before committing to permanent hires through our contract-to-hire services.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Project-Based Staffing: For short-term projects requiring specialized skills, we provide the right talent to meet your project goals efficiently.</span></li>
                                        <li><span><i className="uil uil-check" /></span><span>Executive Search: For leadership and executive-level IT positions, our executive search service identifies top-tier talent to lead your organization to new heights.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <h3>Partner With Us:</h3>
                            <p>Join hands with Conch Digital and let us be your strategic partner in attracting, acquiring, and retaining top IT talent. Together, we can build a strong and innovative workforce that drives your business to success in the digital age. Contact us today to explore how we can elevate your IT recruitment efforts.</p>
                            {/*/.row */}
                        </div>
                        {/* /.container */}
                    </section>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Aboutus;