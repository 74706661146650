import Footer from '../Footer';
import Header from '../Header';
const JavaDevelopers = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Hire Skilled JAVA Developers for Your Next Project</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>Are you in search of a talented Java developer to bring your software projects to life? Look no further! We offer highly skilled and experienced Java developers who are ready to join your team and contribute their expertise to your project's success.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Our JAVA Developers?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Proficiency in Java: Our developers have a deep understanding of the Java programming language and its extensive ecosystem. They are proficient in writing clean, efficient, and scalable code using Java's rich libraries and frameworks.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Full-Stack Capabilities: Our Java developers possess a strong foundation in both front-end and back-end development. They can handle the complete development lifecycle, including server-side logic, database integration, and user interface implementation, ensuring a holistic approach to your projects.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Enterprise Application Development: Java is widely used in the development of enterprise-level applications, and our developers have extensive experience in this domain. They can build scalable and robust applications using frameworks such as Spring, Hibernate, and Java EE, ensuring high performance and maintainability.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Web Development Expertise: Whether you need to build web applications, RESTful APIs, or web services, our Java developers have the expertise to deliver outstanding results. They are skilled in using popular web frameworks such as Spring Boot and JavaServer Faces (JSF) to create secure and efficient web solutions.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Database Integration: Our Java developers are experienced in integrating databases into Java applications. They can work with various database technologies such as MySQL, Oracle, and PostgreSQL, ensuring seamless data storage and retrieval for your applications.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Timely Delivery: We understand the importance of meeting project deadlines. Our developers are committed to delivering projects on time without compromising on quality. They follow a structured development approach and communicate proactively to keep you informed about the progress.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Hiring Process:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Requirement Analysis: We conduct a thorough analysis of your project requirements, including the scope, technology stack, and timeline.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Candidate Selection: Based on your project needs, we handpick the most suitable Java developers from our talent pool.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Interview and Evaluation: You can interview the shortlisted candidates and evaluate their technical skills, experience, and compatibility with your project.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Onboarding: Once you've selected a developer, we handle the onboarding process, ensuring a smooth transition and providing all necessary resources for their success.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Project Management and Support: Throughout the engagement, our dedicated project manager will oversee the development process and provide support, ensuring that your project progresses smoothly.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Ready to Hire a Skilled Java Developer?</h3>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default JavaDevelopers;