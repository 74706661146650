import Footer from '../Footer';
import Header from '../Header';
const StaffAugmentation = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Your Trusted Partner for Staff Augmentation Services</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>Welcome to Conch Digital, where we redefine how businesses augment their workforce to achieve greater efficiency and success. As a leading staff augmentation provider, we offer flexible and customized solutions to empower organizations with top-notch talent to meet their project needs.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Conch Digital?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Access to Elite Talent:</b> We have an extensive network of highly skilled professionals across various industries and domains. Our rigorous screening process ensures that only the best candidates, who possess the right expertise and experience, become part of your team.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Cost-Effective Solution: </b>Staff augmentation enables you to scale your workforce without the overhead costs associated with traditional hiring. You can add specialized talent when and where needed, allowing for greater budget control and optimized resource allocation.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Agility and Flexibility: </b>Market demands can change rapidly, and your staffing needs may fluctuate accordingly. With our staff augmentation services, you can swiftly respond to changing requirements, ensuring that you always have the right team in place.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Quick Onboarding: </b>We understand the importance of time in today's fast-paced business world. Our streamlined onboarding process ensures that our augmented staff seamlessly integrates with your existing team and project, minimizing any disruption to your operations.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Diverse Expertise: </b>From IT professionals to marketing experts, project managers to administrative personnel, we offer a wide range of talent options to cater to your specific needs, regardless of industry or project complexity.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Our Staff Augmentation Process:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Assessment and Requirement Gathering:</b> We begin by understanding your project requirements, organizational goals, and the skill sets needed to achieve success.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Candidate Search: </b>Leveraging our vast talent pool, we identify and shortlist candidates whose skills and expertise align with your project's demands.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Screening and Interviewing:</b> Our team conducts rigorous screening and interviews to ensure that the candidates not only have the necessary technical skills but also fit seamlessly into your company culture.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Onboarding and Support:</b> Once you have selected the candidates, we facilitate the onboarding process and provide ongoing support to both your team and the augmented staff throughout the engagement.</span></li>
                                                    </ul>
                                                    <br></br>
                                                    <h3>Industries We Serve:</h3>
                                                    <p>Our staff augmentation services cater to a diverse range of industries, including but not limited to:</p>
                                                        <ul>
                                                            <li>Information Technology</li>
                                                            <li>Healthcare</li>
                                                            <li>Finance and Banking</li>
                                                            <li>Marketing and Advertising</li>
                                                            <li>Engineering and Construction</li>
                                                            <li>Retail and E-commerce</li>
                                                            <li>Manufacturing</li>
                                                        </ul>
                                                </div>
                                            </div>
                                            <h3>Unlock Your Full Potential with Conch Digital:</h3>
                                            <p>Experience the power of a flexible, skilled, and dynamic workforce. Partner with [Your Staff Augmentation Company Name] and gain a competitive advantage through access to top talent that drives your projects to success. Contact us now to explore how staff augmentation can elevate your business and propel you towards new heights.</p>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default StaffAugmentation;