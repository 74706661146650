import Footer from '../Footer';
import Header from '../Header';
const HealthCareStaffing = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Welcome to Conch Digital - Your Premier Healthcare Staffing Solutions Provider</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>At Conch Digital, we recognize that exceptional healthcare services require exceptional healthcare professionals. That's why we are dedicated to connecting healthcare facilities with top-tier, qualified, and compassionate medical staff. Whether you need temporary staffing, permanent placements, or travel healthcare professionals, we have the expertise and resources to fulfill your staffing needs.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Conch Digital?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Quality Healthcare Professionals:</b> We pride ourselves on sourcing the best talent in the healthcare industry. Our extensive network of healthcare professionals includes registered nurses, licensed practical nurses, certified nursing assistants, allied health professionals, physicians, and more.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Personalized Approach:</b> We understand that every healthcare facility is unique, with specific staffing requirements. Our team takes the time to comprehend your facility's culture, patient demographics, and service needs to provide tailored staffing solutions that fit seamlessly with your team.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Compliance and Credentials: </b>The healthcare industry demands stringent compliance and credentialing standards. Our recruitment process includes rigorous screening, background checks, and verification of licenses and certifications to ensure that all professionals are fully qualified and compliant.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Fast and Efficient Service: </b>Healthcare staffing needs can arise unexpectedly, and we are here to respond quickly. Our team is adept at delivering prompt solutions, so you can maintain optimal staffing levels and continue providing exceptional patient care.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>24/7 Support: </b>Medical staffing can't wait for business hours. That's why we offer around-the-clock support to address any staffing emergencies and concerns promptly.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Our Healthcare Staffing Services:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Temporary Staffing: </b>If you need short-term coverage for vacations, sick leaves, or fluctuations in patient volume, our temporary staffing solutions provide qualified professionals ready to step in when you need them.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Permanent Placements: </b>Finding the right permanent healthcare professional for your facility is crucial for long-term success. We excel in matching healthcare facilities with qualified candidates who align with your values and contribute to your organization's growth.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Travel Healthcare Professionals: </b>Our travel staffing program offers healthcare professionals willing to work on a temporary basis in various locations. Whether you require travel nurses or other healthcare specialists, we've got you covered.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Allied Health Staffing: </b>In addition to nursing and physician staffing, we provide skilled allied health professionals, such as physical therapists, occupational therapists, radiologic technologists, and more.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Emergency Staffing Solutions:</b> We understand that unforeseen circumstances can disrupt your operations. Our emergency staffing solutions ensure that you have the necessary support during challenging times.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Partner With Us Today:</h3>
                                            <p>Discover the healthcare staffing solution that meets your facility's needs and elevates the quality of care you provide. Partner with Conch Digital and let us connect you with the exceptional healthcare professionals you deserve. Contact us now to start building a healthier future together.</p>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default HealthCareStaffing;