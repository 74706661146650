import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <footer className="bg-dark text-inverse">
                <div className="container pt-17 pt-md-19 pb-13 pb-md-15">
                    <div className="row gy-6 gy-lg-0">
                        <div className="col-md-4 col-lg-3">
                            <div className="widget">
                                <img className="mb-4" src="/assets/img/logo-dark-black.png" alt />
                                <p className="mb-4">© 2023 Conch Digital. <br className="d-none d-lg-block" />All rights reserved.</p>
                                <nav className="nav social social-white">
                                    <a href="#"><i className="uil uil-twitter" /></a>
                                    <a href="#"><i className="uil uil-facebook-f" /></a>
                                    <a href="#"><i className="uil uil-instagram" /></a>
                                </nav>
                                {/* /.social */}
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                        <div className="col-md-4 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">USA Address</h4>
                                <address className="pe-xl-15 pe-xxl-17">1312 17th Street Unit #2229, Denver CO 80202</address>
                                <a href="mailto:requirements@conchinc.com">requirements@conchinc.com</a><br />559 831 3959
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                        <div className="col-md-4 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Learn More</h4>
                                <ul className="list-unstyled  mb-0">
                                    <li><Link to={"/aboutus"}>About Us</Link></li>
                                    <li><Link to={"/testimonials"}>Testimonials</Link></li>
                                    <li><Link to={"/contactus"}>Contact</Link></li>
                                </ul>
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                    </div>
                    {/*/.row */}
                </div>
                {/* /.container */}
            </footer>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </div>

    );
};
export default Footer;