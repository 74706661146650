import Footer from '../Footer';
import Header from '../Header';
const HealthCare = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Empowering Healthcare by building innovation led solutions</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p><b>Conch Digital</b> is your trusted partner in healthcare software development. We specialize in creating cutting-edge software solutions that revolutionize the way healthcare organizations operate, providing seamless integration, improved efficiency, and better patient outcomes.</p>
                                            <p>At <b>Conch Digital</b>, we understand the critical role that technology plays in the healthcare industry. With our team of skilled developers, healthcare experts, and data scientists, we are committed to designing software solutions that address the unique challenges faced by healthcare providers, payers, and patients.</p>
                                            <h3 className="h2 mb-3 mt-9">Our Services</h3>
                                            <p><b>Electronic Health Records (EHR) Systems:</b> We develop intuitive and secure EHR systems that enable healthcare providers to manage patient data, medical history, and treatment plans efficiently. Our solutions comply with industry standards to ensure data privacy and seamless interoperability.</p>
                                            <p><b>Practice Management Software:</b> Our practice management software streamlines administrative tasks, appointment scheduling, billing, and claims processing. This allows healthcare professionals to focus more on patient care and less on paperwork.</p>
                                            <p><b>Telemedicine Solutions:</b> Our telemedicine applications facilitate virtual consultations, remote monitoring, and secure patient communication. With our user-friendly interfaces, patients can connect with healthcare providers from the comfort of their homes.</p>
                                            <p><b>Medical Imaging and PACS:</b> We create advanced medical imaging software that helps healthcare providers analyze and manage diagnostic images, improving diagnostic accuracy and collaboration among medical teams.</p>
                                            <p><b>Health Information Exchange (HIE):</b> Our HIE solutions enable secure data exchange between healthcare organizations, fostering better coordination of care and reducing redundant tests or procedures.</p>

                                            <h3 className="h2 mb-3 mt-9">Why Choose Us</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Healthcare Expertise: Our team includes healthcare domain experts who understand the unique challenges and regulations of the industry.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Customized Solutions: We tailor our software development to suit your organization's specific needs and workflows.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Security and Compliance: Data security and compliance are paramount in healthcare. Our software solutions adhere to industry standards and regulations, ensuring the protection of sensitive patient information.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>User-Centric Design: Our user-centric approach ensures that our software is easy to use, reducing training time and improving overall user satisfaction.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Scalable and Future-Proof: Our software solutions are designed to grow with your organization and adapt to emerging technologies, ensuring long-term success.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/*/.row */}
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default HealthCare;