import Footer from '../Footer';
import Header from '../Header';
const FullStack = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Hire Skilled Full Stack Developers for Your Software Development Projects</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>Are you looking to hire a talented full stack developer to handle all aspects of your software development projects? Look no further! We offer highly skilled and experienced full stack developers who are ready to join your team and bring your projects to life.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Our Full Stack Developers?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Expertise in .NET Framework: Our developers are proficient in the Microsoft .NET framework and have deep knowledge of C#, ASP.NET, and other related technologies. They are well-versed in building robust and scalable applications using the .NET stack</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Front-end Development: Our full stack developers excel in front-end development, creating intuitive and visually appealing user interfaces. They are skilled in HTML, CSS, JavaScript, and modern front-end frameworks like React, Angular, and Vue.js. They can build responsive and interactive web applications that provide an exceptional user experience.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Back-end Development: Our full stack developers are proficient in server-side technologies and frameworks such as Node.js, Django, Ruby on Rails, and Laravel. They can handle server-side logic, database integration, and API development, ensuring a robust and scalable back-end for your applications.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Database Management: Our full stack developers have experience working with various databases like MySQL, PostgreSQL, MongoDB, and more. They can design and optimize database structures, write efficient queries, and ensure smooth data management for your applications.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Web Application Development: Whether you need to build a web application from scratch or enhance an existing one, our full stack developers have the expertise to deliver outstanding results. They can handle all stages of web application development, from gathering requirements to deployment, ensuring a seamless and efficient development process.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Timely Delivery: We understand the importance of meeting project deadlines. Our developers are committed to delivering projects on time without compromising on quality. They follow a structured development approach and communicate proactively to keep you informed about the progress.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Hiring Process:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Requirement Analysis: We conduct a thorough analysis of your project requirements, including the scope, technology stack, and timeline.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Candidate Selection: Based on your project needs, we handpick the most suitable FullStack developers from our talent pool.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Interview and Evaluation: You can interview the shortlisted candidates and evaluate their technical skills, experience, and compatibility with your project.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Onboarding: Once you've selected a developer, we handle the onboarding process, ensuring a smooth transition and providing all necessary resources for their success.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Project Management and Support: Throughout the engagement, our dedicated project manager will oversee the development process and provide support, ensuring that your project progresses smoothly.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Ready to Hire a Skilled FullStack Developer?</h3>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default FullStack;