import Footer from '../Footer';
import Header from '../Header';
const Manufacturing = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Manufacturing Software Solutions</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p><b>Conch Digital</b> your trusted partner in manufacturing software development. We specialize in creating innovative and tailored software solutions that optimize manufacturing processes, increase efficiency, and drive productivity. With our cutting-edge technology, we aim to revolutionize the way manufacturing businesses operate in the digital age.</p>
                                            <p>At <b>Conch Digital</b>, we understand the unique challenges faced by the manufacturing industry. Our team of skilled developers and manufacturing experts is committed to delivering robust and user-friendly software solutions that address the complexities of modern manufacturing.</p>
                                            <h3 className="h2 mb-3 mt-9">Our Services</h3>
                                            <p><b>Manufacturing Execution Systems (MES): </b>We develop comprehensive MES solutions that enable real-time monitoring of production processes, inventory management, and quality control.</p>
                                            <p><b>Inventory Management Software: </b>Our inventory management systems help manufacturers efficiently track raw materials, work-in-progress, and finished goods, ensuring optimal stock levels.</p>
                                            <p><b>Production Planning and Scheduling: </b>We create software that optimizes production schedules, resource allocation, and workflow to streamline manufacturing operations.</p>
                                            <p><b>Quality Management Systems (QMS): </b>Our QMS solutions ensure consistent product quality by automating quality checks, audits, and non-conformance management.</p>
                                            <p><b>Supply Chain Management (SCM): </b>Our SCM software facilitates end-to-end supply chain visibility, fostering efficient supplier collaboration and reducing lead times.</p>

                                            <h3 className="h2 mb-3 mt-9">Why Choose Us</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Manufacturing Expertise: Our team possesses deep knowledge of the manufacturing industry, allowing us to deliver software solutions that align perfectly with your business goals.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Customized Approach: We understand that each manufacturing operation is unique. Our software development process is tailored to meet your specific needs and workflows.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>User-Centric Design: Our software is designed with a focus on user experience, ensuring easy adoption and optimal productivity for your manufacturing teams.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Security and Compliance: We prioritize data security and compliance, adhering to industry standards and regulations to protect sensitive manufacturing data.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Scalable and Future-Ready: Our solutions are designed to grow with your business and adapt to emerging technologies in the manufacturing sector.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/*/.row */}
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Manufacturing;