import Footer from './Footer';
import Header from './Header';
const Contact = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow text-white">
                    <div className="container pt-18 pt-md-20 pb-21 pb-md-21 text-center">
                        <div className="row">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
                                <h1 className="display-1 text-dark mb-3">Get in Touch</h1>
                                <p className="lead px-xl-10 px-xxl-10 text-dark">Contact Us & Let's Collaborate!</p>
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                {/* /section */}
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col mt-n19 mb-16">
                                <div className="card shadow-lg">
                                    <div className="row gx-0">
                                        <img className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-none d-md-block" src="/assets/img/photos/tm1.jpg">
                                        </img>
                                        {/*/column */}
                                        <div className="col-lg-6">
                                            <div className="p-10 p-md-11 p-lg-13">
                                                <h2 className="display-4 mb-3">Let’s Talk</h2>
                                                <p className="lead fs-lg">Schedule a call today to see how we can modernize your business and enhance your productivity by applying technology in the right quantum at the right place!</p>

                                                <div className="d-flex flex-row">
                                                    <div>
                                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt" /> </div>
                                                    </div>
                                                    <div>
                                                        <h5 className="mb-1">USA Address</h5>
                                                        <address>1312 17th Street Unit #2229, Denver CO 80202</address>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row">
                                                    <div>
                                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume" /> </div>
                                                    </div>
                                                    <div>
                                                        <h5 className="mb-1">Phone</h5>
                                                        <p>559 831 3959</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row">
                                                    <div>
                                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-envelope" /> </div>
                                                    </div>
                                                    <div>
                                                        <h5 className="mb-1">E-mail</h5>
                                                        <p className="mb-0"><a href="mailto:requirements@conchinc.com" className="link-body">requirements@conchinc.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/column */}
                                    </div>
                                    {/*/.row */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                {/* /section */}
                <section className="wrapper bg-light">
                    <div className="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.5245373358916!2d-104.9966735!3d39.7503299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c78db305fc9a7%3A0x76b2cdee116daa79!2s1312%2017th%20St%2C%20Denver%2C%20CO%2080202%2C%20USA!5e0!3m2!1sen!2sin!4v1689842481089!5m2!1sen!2sin" style={{ width: '100%', height: 500, border: 0 }} allowFullScreen />
                    </div>
                    {/* /.map */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Contact;