import Footer from '../Footer';
import Header from '../Header';
const Education = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">EdTech Solutions</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p><b>Conch Digital</b> your trusted partner in education technology. We are dedicated to transforming the learning experience through innovative software solutions that empower educators, students, and institutions alike. With our cutting-edge technology, we aim to revolutionize education and foster a future-ready generation.</p>
                                            <p>At <b>Conch Digital</b>, we believe in the power of technology to enhance education and bridge the gap between traditional teaching methods and modern learning needs. Our team of skilled developers and education experts is committed to creating robust and user-friendly software solutions that cater to the diverse needs of the education sector.</p>
                                            <h3 className="h2 mb-3 mt-9">Our Services</h3>
                                            <p><b>Learning Management Systems (LMS): </b>We develop intuitive LMS platforms that facilitate online course management, content delivery, assessments, and collaboration, making learning accessible anytime, anywhere.</p>
                                            <p><b>Virtual Classroom Solutions: </b>Our virtual classroom software enables real-time interactions between teachers and students, replicating the traditional classroom experience in a digital environment.</p>
                                            <p><b>E-Learning Content Development: </b>We create engaging and interactive e-learning content, including multimedia lessons, simulations, and gamified educational experiences.</p>
                                            <p><b>Student Information Systems (SIS): </b>Our SIS solutions streamline administrative tasks like attendance tracking, grades management, and communication between schools, students, and parents.</p>
                                            <p><b>Assessment and Grading Tools:</b> Our assessment software helps educators create, administer, and analyze assessments to gauge student progress and identify areas for improvement.</p>

                                            <h3 className="h2 mb-3 mt-9">Why Choose Us</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Education Expertise: Our team includes educators and learning specialists who understand the unique requirements of the education sector.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Tailored Solutions: We create customized software to align with your institution's specific curriculum and educational goals.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>User-Friendly Interface: Our software is designed with a focus on user experience, ensuring easy adoption by students, teachers, and administrators.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Scalable and Future-Ready: Our solutions are designed to evolve with your institution's needs and integrate seamlessly with emerging technologies.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Data Security and Compliance: We prioritize the security and privacy of student and institutional data, adhering to industry standards and regulations.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/*/.row */}
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Education;