import Footer from '../Footer';
import Header from '../Header';
const MeanStack = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Hire Skilled MEAN Developers for Your Next Project</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>Are you searching for a talented MEAN stack developer to build powerful and scalable web applications? Look no further! We offer highly skilled and experienced MEAN stack developers who are ready to join your team and bring your web development projects to life.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Our MEAN Developers?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Expertise in MEAN Stack: Our developers have deep knowledge and expertise in the MEAN stack, which includes MongoDB, Express.js, Angular, and Node.js. They are proficient in building end-to-end web applications using this powerful and popular technology stack.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>MongoDB Database Development: Our developers are skilled in MongoDB, a NoSQL database, and can design and develop efficient database structures to meet your application's needs. They can handle data modeling, database queries, and performance optimization, ensuring the smooth functioning of your application.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Express.js Backend Development: Our MEAN stack developers excel in backend development using Express.js. They can build robust and scalable APIs, implement business logic, and handle server-side operations to create a reliable and efficient backend for your web applications.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Angular Frontend Development: Whether you need to build a single-page application or a complex user interface, our MEAN stack developers are proficient in Angular. They can create responsive and interactive user interfaces, implement client-side validation, and ensure a seamless user experience across different devices.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Node.js Development: Our developers leverage the power of Node.js to build fast and scalable server-side applications. They can handle real-time communication, integrate third-party APIs, and develop efficient server-side logic using JavaScript, providing a smooth and responsive user experience.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Timely Delivery: We understand the importance of meeting project deadlines. Our MEAN stack developers follow a structured development approach and adhere to project timelines. They are committed to delivering projects on time without compromising on quality, ensuring your satisfaction and success.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Hiring Process:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Requirement Analysis: We conduct a thorough analysis of your project requirements, including the scope, technology stack, and timeline.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Candidate Selection: Based on your project needs, we handpick the most suitable MEAN developers from our talent pool.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Interview and Evaluation: You can interview the shortlisted candidates and evaluate their technical skills, experience, and compatibility with your project.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Onboarding: Once you've selected a developer, we handle the onboarding process, ensuring a smooth transition and providing all necessary resources for their success.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Project Management and Support: Throughout the engagement, our dedicated project manager will oversee the development process and provide support, ensuring that your project progresses smoothly.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Ready to Hire a Skilled MEAN Developer?</h3>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default MeanStack;