import React from 'react';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Root from './Pages/Root';
import Contact from './Pages/Contact';
import HealthCare from './Pages/Industries/Healthcare';
import Travel from './Pages/Industries/Travel';
import Education from './Pages/Industries/Education';
import Fintech from './Pages/Industries/Fintech';
import Insurance from './Pages/Industries/Insurance';
import Retail from './Pages/Industries/Retail';
import Manufacturing from './Pages/Industries/Manufacturing';
import DotnetDevelopers from './Pages/Developers/DotnetDevelopers';
import JavaDevelopers from './Pages/Developers/JavaDevelopers';
import FullStack from './Pages/Developers/FullStack';
import Salesforce from './Pages/Developers/Salesforce';
import MeanStack from './Pages/Developers/MeanStack';
import MernStack from './Pages/Developers/MernStack';
import OracleDevelopers from './Pages/Developers/OracleDevelopers';
import SAPDevelopers from './Pages/Developers/SAPDevelopers';
import USITRecruitment from './Pages/Services/USITRecruitment';
import HealthCareStaffing from './Pages/Services/HealthCareStaffing';
import SAPConsulting from './Pages/Services/SAPConsulting';
import GlobalStaffing from './Pages/Services/GlobalStaffing';
import StaffAugmentation from './Pages/Services/StaffAugmentation';
import Aboutus from './Pages/Aboutus';
import Testimonials from './Pages/Testimonials';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/healthcare',
    element: <HealthCare />
  },
  {
    path: '/travel',
    element: <Travel />
  },
  {
    path: '/education',
    element: <Education />
  },
  {
    path: '/fintech',
    element: <Fintech />
  },
  {
    path: '/insurance',
    element: <Insurance />
  },
  {
    path: '/retail',
    element: <Retail />
  },
  {
    path: '/dotnet-developers',
    element: <DotnetDevelopers />
  },
  {
    path: '/mern-developers',
    element: <MernStack />
  },
  {
    path: '/mean-developers',
    element: <MeanStack />
  },
  {
    path: '/salesforce-experts',
    element: <Salesforce />
  },
  {
    path: '/fullstack-developers',
    element: <FullStack />
  },
  {
    path: '/sap-developers',
    element: <SAPDevelopers />
  },
  {
    path: '/us-it-recruitments',
    element: <USITRecruitment />
  },
  {
    path: '/java-developers',
    element: <JavaDevelopers />
  },
  {
    path: '/healthcare-staffing',
    element: <HealthCareStaffing />
  },
  {
    path: '/staff-augmentation',
    element: <StaffAugmentation />
  },  
  {
    path: '/global-staffing',
    element: <GlobalStaffing />
  },
  {
    path: '/aboutus',
    element: <Aboutus />
  },
  {
    path: '/testimonials',
    element: <Testimonials />
  },
  ,
  {
    path: '/Manufacturing',
    element: <Manufacturing />
  },
  ,
  {
    path: '/oracle-developers',
    element: <OracleDevelopers />
  },
  ,
  {
    path: '/sap-staffing',
    element: <SAPConsulting />
  },
]);
function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;