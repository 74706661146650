import Footer from './Footer';
import Header from './Header';
const Testimonials = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-gradient-primary">
                    <div className="container pt-12 pt-lg-8 pb-14 pb-md-17">
                        <div className="row text-center">
                            <div className="col-lg-8 offset-lg-2">
                                <h2 className="fs-16 text-uppercase text-primary mb-3">Happy Customers</h2>
                                <h3 className="display-3 mb-10 px-xxl-10">Don't take our word for it. See what customers are saying about us.</h3>
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                        <div className="grid">
                            <div className="row isotope gy-6">
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2" />
                                            <blockquote className="border-0 mb-0">
                                                <p>“I had an amazing experience working with Conch Digital. From the initial consultation to the final placement, their team was professional, attentive, and genuinely interested in finding the best fit for our company. They took the time to understand our unique requirements and presented us with highly qualified candidates who exceeded our expectations. The entire process was streamlined, and we were able to onboard the perfect IT professional within a short timeframe. I highly recommend Conch Digital to any organization looking for top-notch IT recruitment services.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12" src="./assets/img/avatars/avatar.jpg" srcSet="./assets/img/avatars/te1@2x.jpg 2x" alt />
                                                    <div className="info">
                                                        <h5 className="mb-1">Coriss</h5>
                                                        <p className="mb-0">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/*/column */}
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2" />
                                            <blockquote className="border-0 mb-0">
                                                <p>“I cannot praise Conch Digital enough for their outstanding service. They understood the urgency of our IT staffing needs and quickly sourced the ideal candidate for our critical project. The entire team was highly responsive and provided continuous updates throughout the recruitment process. They presented us with a diverse pool of talented candidates, making the selection process seamless. We are impressed with the level of expertise and professionalism demonstrated by Conch Digital and will definitely work with them again in the future.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12" src="./assets/img/avatars/avatar.jpg" srcSet="./assets/img/avatars/te2@2x.jpg 2x" alt />
                                                    <div className="info">
                                                        <h5 className="mb-1">Cory</h5>
                                                        <p className="mb-0">Marketing Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/*/column */}
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <span className="ratings five mb-2" />
                                            <blockquote className="border-0 mb-0">
                                                <p>“Working with Conch Digital was a breath of fresh air. They demonstrated an in-depth understanding of our industry and technical requirements, which made all the difference in finding the right IT professionals for our team. The recruiters were attentive to our feedback and always went the extra mile to ensure that the candidates presented were the perfect fit for our organization. We are thrilled with the high-caliber candidates they sourced, and our new IT team members have made an immediate impact. I highly recommend Conch Digital for their exceptional services and dedication to client satisfaction.”</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-12" src="./assets/img/avatars/avatar.jpg" srcSet="./assets/img/avatars/te3@2x.jpg 2x" alt />
                                                    <div className="info">
                                                        <h5 className="mb-1">Nikolas</h5>
                                                        <p className="mb-0">Sales Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/*/column */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.grid-view */}
                    </div>
                    {/* /.container */}
                </section>

            </div>
            <Footer></Footer>
        </>
    );
};
export default Testimonials;