import Footer from '../Footer';
import Header from '../Header';
const Travel = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Travel Tech Solutions</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p><b>Conch Digital</b> your premier partner in travel and hospitality software development. We specialize in creating innovative and scalable software solutions that elevate the travel experience for both travelers and industry professionals. Our cutting-edge technology is designed to streamline operations, boost efficiency, and enhance customer satisfaction.</p>
                                            <p>At <b>Conch Digital</b>, we understand the dynamic nature of the travel and hospitality industry. Our team of travel enthusiasts and tech experts is passionate about crafting solutions that address the unique challenges faced by hotels, airlines, travel agencies, and other travel-related businesses.</p>
                                            <h3 className="h2 mb-3 mt-9">Our Services</h3>
                                            <p><b>Online Booking Platforms: </b>We develop user-friendly and secure online booking systems that enable travelers to easily search, compare, and book flights, hotels, car rentals, and other travel services.</p>
                                            <p><b>Hotel Property Management Systems (PMS):</b> Our PMS solutions streamline hotel operations, including reservations, check-ins, housekeeping, billing, and guest communications.</p>
                                            <p><b>Travel Agency Software: </b>Our travel agency solutions empower travel professionals with tools for itinerary planning, CRM, supplier management, and personalized travel recommendations.</p>
                                            <p><b>Mobile Applications:</b> We create feature-rich and intuitive mobile apps that provide travelers with on-the-go access to their itineraries, booking details, and real-time travel updates.</p>
                                            <p><b>Revenue Management Systems:</b> Our revenue management solutions leverage data analytics and pricing algorithms to optimize revenue for hotels and airlines.</p>

                                            <h3 className="h2 mb-3 mt-9">Why Choose Us</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Industry Expertise: Our team possesses in-depth knowledge of the travel and hospitality sector, allowing us to deliver tailored solutions that cater to your specific needs.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Innovative Approach: We stay at the forefront of technology trends, leveraging the latest advancements to create cutting-edge software solutions.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Seamless Integration: Our software is designed to integrate seamlessly with existing systems, reducing disruptions and ensuring a smooth transition.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Security and Compliance: We prioritize the security and privacy of sensitive customer data, adhering to industry standards and data protection regulations.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>User-Centric Design: Our user-centric design philosophy ensures that our software is intuitive and easy to use, enhancing the overall customer experience.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/*/.row */}
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Travel;