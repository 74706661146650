import Footer from '../Footer';
import Header from '../Header';
const Salesforce = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Hire Salesforce Experts for Your Business Growth</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>Are you looking to optimize your Salesforce implementation or need assistance in unleashing the full potential of the platform for your business? Look no further! Our team of highly skilled and certified Salesforce experts is here to help you streamline your processes, drive customer engagement, and boost overall productivity.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Our Salesforce Experts?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Extensive Experience: Our team comprises seasoned Salesforce professionals with years of hands-on experience in working with businesses across various industries. We have successfully tackled diverse challenges and are well-versed in tailoring solutions to meet your unique needs.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Certified Professionals: All our Salesforce experts hold industry-recognized certifications, including Salesforce Administrator, Salesforce Developer, and Salesforce Consultant. You can trust that your Salesforce instance will be in capable hands.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Customized Solutions: We understand that every business is different, and one size does not fit all. Our experts take the time to understand your business requirements thoroughly, ensuring that the solutions we propose align perfectly with your goals.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Efficient Implementation: Whether you are starting from scratch or need to enhance your current Salesforce setup, our experts can efficiently implement the platform, ensuring minimal disruption to your operations.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Integration and Automation: We excel in integrating Salesforce with other systems, streamlining your workflows, and automating repetitive tasks. This way, you can focus on what matters most - growing your business.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Comprehensive Support: Our partnership with you doesn't end with implementation. We provide ongoing support and maintenance services to ensure that your Salesforce system continues to perform optimally.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Our Salesforce Services Include:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span>Sales Cloud Setup and Customization: Enhance your sales processes, track leads, manage opportunities, and optimize sales performance with our tailored Sales Cloud solutions.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Service Cloud Implementation: Provide exceptional customer service with our Service Cloud expertise, empowering your support teams to deliver seamless customer experiences.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Marketing Automation: Optimize your marketing efforts with Marketing Cloud, creating personalized campaigns and engaging with your customers at the right time and through the right channels.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Community Cloud Development: Foster collaboration and engagement with your customers, partners, and employees through our customized Community Cloud solutions.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span>Salesforce Integrations: Seamlessly connect Salesforce with other applications in your tech stack, ensuring data consistency and a unified view of your business operations.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Ready to Hire a Salesforce Expert?</h3>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default Salesforce;