import HomePage from './HomePage';
import Footer from './Footer';
import Header from './Header';

const Root = () => {
    return (
        <>
        <div className="content-wrapper">
            <Header />
            <HomePage />
        </div>
        <Footer></Footer>
        </>
    );
};
export default Root;