import Footer from '../Footer';
import Header from '../Header';
const GlobalStaffing = () => {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <section className="wrapper bg-yellow">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-1 mb-5">Welcome to Conch Digital - Your Gloabl Staffing Solutions Provider</h1>
                                    {/* /.post-meta */}
                                </div>
                                {/* /.post-header */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
                <section className="wrapper bg-light">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p>At Conch Digital, we take pride in connecting exceptional talent with leading companies across the globe. Our mission is to provide top-notch staffing solutions that empower businesses to thrive and help professionals achieve their career aspirations. With years of industry expertise and a vast network of resources, we are committed to delivering the highest quality staffing services tailored to your unique needs.</p>
                                            <h3 className="h2 mb-3 mt-9">Why Choose Conch Digital?</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Global Reach, Local Expertise:</b> We operate on an international scale, serving clients and candidates from diverse regions and industries. Our local teams are well-versed in the unique challenges and opportunities of their respective markets, ensuring a seamless experience wherever you are.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Dedicated Team of Experts:</b>Our team of skilled and experienced recruiters goes above and beyond to understand your requirements fully. Whether you need temporary, permanent, or project-based staffing solutions, we are equipped to handle your staffing needs efficiently.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Extensive Talent Pool: </b>Access our vast talent pool, brimming with qualified candidates across various domains. From entry-level to C-suite positions, we have the talent you need to fill crucial roles and drive your organization forward.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Customized Solutions: </b>We believe that one size does not fit all. Our staffing solutions are tailored to align with your company's culture, values, and specific requirements. This ensures that we find the right fit for both the candidate and your organization.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Swift and Efficient Hiring Process:</b> Time is of the essence in today's fast-paced business world. Our streamlined hiring process ensures that you find the right candidate quickly and without unnecessary delays.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Confidentiality and Security: </b>We understand the importance of confidentiality in sensitive hiring processes. Rest assured that your information and hiring decisions are handled with the utmost discretion and security.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Our Staffing Services:</h3>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-12">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check" /></span><span><b>Temporary Staffing: </b>Need skilled professionals for short-term projects or to cover seasonal demands? Our temporary staffing solutions can provide you with qualified candidates who can hit the ground running.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Permanent Staffing: </b>Finding the perfect match for your permanent positions is crucial for your organization's success. Our dedicated team employs a rigorous screening process to ensure you get the best talent available.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Executive Search: </b>When it comes to executive roles, we understand the significance of finding visionary leaders who can drive your company's growth. Our executive search service identifies top-tier talent capable of making a lasting impact.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Project-Based Staffing: </b>For time-bound initiatives and specialized projects, our project-based staffing services connect you with professionals possessing the right skill sets and expertise to excel in their roles.</span></li>
                                                        <li><span><i className="uil uil-check" /></span><span><b>Industry Expertise:</b>Our experience spans across various industries, including IT, healthcare, finance, engineering, and more. We understand the unique staffing challenges in each sector and are well-equipped to address them.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Partner With Us Today:</h3>
                                            <p>Experience the difference that exceptional talent can make in your organization's success. Whether you are an employer looking for top talent or a professional seeking new opportunities, we are here to support your journey. Get in touch with us today to explore how we can help you achieve your goals.</p>
                                            <a href="mailto:info@conchinc.com" className="btn btn-primary rounded-pill">Contact Us</a>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.blog */}
                            </div>
                            {/* /column */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <Footer></Footer>
        </>
    );
};
export default GlobalStaffing;