import { Link } from "react-router-dom";

const Header = () => {
    return (
        <header className="wrapper bg-yellow">
            <nav className="navbar navbar-expand-lg center-nav transparent navbar-dark">
                <div className="container flex-lg-row flex-nowrap align-items-center">
                    <div className="navbar-brand w-100" style={{marginRight:'80px'}}>
                        <Link to={"/"}>
                            <img className="logo-dark" src="/assets/img/logo-light-color.png" alt />
                            <img className="logo-light" src="/assets/img/logo-light-color.png" alt />
                        </Link>
                    </div>
                    <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                        <div className="offcanvas-header d-lg-none">
                            <h3 className="text-white fs-30 mb-0">Conch</h3>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div>
                        <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to={"/aboutus"}>About Us</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Services</Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="dropdown-item" to={"/us-it-recruitments"}>US IT Recruitment</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/global-staffing"}>Global Staffing</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/staff-augmentation"}>Staff Augmentation</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/healthcare-staffing"}>HealthCare Staffing</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/sap-staffing"}>SAP Services</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Hire Developers</Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="dropdown-item" to={"/sap-developers"}>Hire SAP Developers</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/oracle-developers"}>Hire Oracle Developers</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/dotnet-developers"}>Hire .Net Developers</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/mern-developers"}>Hire MERN Stack</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/mean-developers"}>Hire MEAN Stack</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/salesforce-experts"}>Salesforce Experts</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/fullstack-developers"}>Hire FullStack Developers</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/java-developers"}>Hire Java Developers</Link></li>
                                    </ul>
                                </li>                                
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Industries</Link>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link className="dropdown-item" to={"/insurance"}>Insurance</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/healthcare"}>Healthcare</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/education"}>Education</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/travel"}>Travel & Hospitality</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/retail"}>Retails & E-Commerce</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/fintech"}>Fintech</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item" to={"/Manufacturing"}>Manufacturing</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to={"/testimonials"}>Testimonials</Link>
                                </li>
                            </ul>
                            {/* /.navbar-nav */}
                        </div>
                        {/* /.offcanvas-body */}
                    </div>
                    {/* /.navbar-collapse */}
                    <div className="navbar-other w-100 d-flex ms-auto">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item d-none d-md-block">
                                <Link to={"/contact"} className="btn btn-sm btn-primary rounded">Contact</Link>
                            </li>
                            <li className="nav-item d-lg-none">
                                <button className="hamburger offcanvas-nav-btn"><span /></button>
                            </li>
                        </ul>
                        {/* /.navbar-nav */}
                    </div>
                    {/* /.navbar-other */}
                </div>
                {/* /.container */}
            </nav>
            {/* /.navbar */}
        </header>


    )
};
export default Header;